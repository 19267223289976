import React from "react"
import { gsap } from "gsap"
import Tree from "./tree"


//const Trees = () => {
class Trees extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  state = {
    assets: {}
  }

  componentDidMount = () => {
    
  }

  updateDimensions = (bgWidth, bgHeight) => {
    //scaleFullscreen(this.refs.treesRef, bgWidth, bgHeight, bgWidth-100, bgHeight-100);
    
  }

  showTrees = () => {
    for (let i = 0; i <= 36; i++) {				
      setTimeout(function () {
        gsap.to(this.refs.treesRef, 0.1, { autoAlpha:1.0 } );
        this.refs[`tree${i}`].showTree();
      }.bind(this), Math.random() * 800, 1);
    }
  }

  hideTrees = () => {
    for (let i = 0; i <= 36; i++) {				
      setTimeout(function () {
        gsap.to(this.refs.treesRef, 0.1, { autoAlpha:1.0 } );
        this.refs[`tree${i}`].hideTree();
      }.bind(this), Math.random() * 800, 1);
    }
  }
  
  render() {
    return (
      <div ref="treesRef" className="introTrees" style={{ left: "0", top: "325px" }} >
        <Tree ref="tree36" num={"36"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree35" num={"35"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree34" num={"34"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree33" num={"33"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree32" num={"32"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree31" num={"31"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree30" num={"30"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree29" num={"29"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree28" num={"28"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree27" num={"27"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree26" num={"26"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree25" num={"25"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree24" num={"24"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree23" num={"23"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree22" num={"22"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree21" num={"21"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree20" num={"20"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree19" num={"19"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree18" num={"18"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree17" num={"17"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree16" num={"16"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree15" num={"15"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree14" num={"14"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree13" num={"13"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree12" num={"12"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree11" num={"11"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree10" num={"10"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree9" num={"09"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree8" num={"08"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree7" num={"07"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree6" num={"06"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree5" num={"05"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree4" num={"04"} file={this.props.treeA} type={"A"} />
        <Tree ref="tree3" num={"03"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree2" num={"02"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree1" num={"01"} file={this.props.treeB} type={"B"} />
        <Tree ref="tree0" num={"00"} file={this.props.treeB} type={"B"} />
      </div>
    )
  }
}

export default Trees
