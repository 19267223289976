import React from "react"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { navigate } from 'gatsby';
import { gsap, Sine } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { frameAnimation, frameAnimationReversed, scaleFullscreen, preloadImages } from "../../helpers"
import Trees from "../../components/intro/trees"
import Preloader from "../../components/preloader/preloader"

let bgWidth = 1289;
//let bgHeight = 723;
let watercolorWidth = 644;
let watercolorScale = bgWidth / watercolorWidth;
let globalState, data, preloadData;

//const IndexPage = () => (
class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[0];
    preloadData = globalState.assets[1];

    this.planeVideoRef = this.planeLandingVideoRef = this.planeLandingEndLoopVideo = this.watercolorRef = this.titleImagineRef = this.titleWouldRef = this.titleWelcomeRef = this.titleGogglesRef = this.treesRef = this.watertowerRef = this.goBtnRef = this.brothersRef = this.richardRef = this.bandRef = this.timeout1 = this.timeout2 = this.timeout3 = this.timeout4 = this.timeout5 = this.timeout6 = null;
  }

  state = {
    isActivated: false,
    loadNum: 0,
    totalLoadNum: 0
  }

 
  componentDidMount = () => {

    console.log("intro component did mount: globalState.isActivated: " + globalState.isActivated);

    //If yes animate in title
    if(globalState.isActivated){
      //Start animations after delay
      gsap.delayedCall(1, this.initAnimation);
    }
  }

  initAnimation = () => {
    
    //1. Set isActivated locally and globally
    this.setState({ isActivated: true });
    globalState.updateIsActivated(true);

    //Update dimensions
    this.updateDimensions();

    //play background video
    this.planeVideoRef.playVideo();

    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);

    //Add base track
    globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2);  //basetrack (loop)
    globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.soundfile1);  //richard (no loop)
    globalState.baseAudioRef.updateTrack(4, globalState.cdnUrl + data.flysound);    //fly (no loop)
    

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1, 
      globalState.cdnUrl + preloadData.text2, 
      globalState.cdnUrl + preloadData.text3, 
      globalState.cdnUrl + preloadData.text4, 
      globalState.cdnUrl + preloadData.planeLandingVideoPoster, 
      globalState.cdnUrl + preloadData.planeLandingEndLoopVideoPoster
    ], this.preloadCallback);

    //Imagine your hometown never changed...
    this.timeout1 = setTimeout(function () {
      gsap.to(this.titleImagineRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.titleImagineRef, 758, 270, 5, 12, 59, 0.2, 0);
    }.bind(this), 500);

    //Would it be so bad?
    this.timeout2 = setTimeout(function () {
      gsap.to(this.titleWouldRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.titleWouldRef, 602, 115, 3, 9, 25, 0.2, 0);
    }.bind(this), 5000);

    //Hide Imagine title
    gsap.to([this.titleImagineRef, this.titleWouldRef], 0.3, { autoAlpha: 0.0, delay: 7.6 });

    //Watercolour
    this.timeout3 = setTimeout(function () {
      gsap.to(this.watercolorRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.watercolorRef, 644, 362, 6, 5, 29, 0.2, 0);
    }.bind(this), 7900);

    //Show trees
    this.timeout4 = setTimeout(function () {
      this.treesRef.showTrees();
    }.bind(this), 8900);

    //Brothers
    gsap.to(this.brothersRef, 0.3, { autoAlpha: 1.0, delay: 9.5, scale: 1, ease: Sine.easeOut, onComplete: this.showNext });

    //Band 
    gsap.to(this.bandRef, 0.3, { autoAlpha: 1.0, delay: 9.7, scale: 1, ease: Sine.easeOut });

    //Watertower
    gsap.to(this.watertowerRef, 0.3, { autoAlpha: 1.0, delay: 9.9, scale: 1, ease: Sine.easeOut });

    //Welcome to Pine Point
    this.timeout5 = setTimeout(function () {
      gsap.to(this.titleWelcomeRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.titleWelcomeRef, 682, 179, 3, 7, 21, 0.2, 0);
    }.bind(this), 11300);

    //By the Goggles
    this.timeout6 = setTimeout(function () {
      gsap.to(this.titleGogglesRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.titleGogglesRef, 313, 78, 6, 15, 88, 0.2, 0);
    }.bind(this), 13400);

    gsap.to(this.richardRef, 0.3, { autoAlpha: 1.0, delay: 13.9, scale: 1.2, ease: Sine.easeOut });
    gsap.to(this.goBtnRef, 0.3, { autoAlpha: 1.0, delay: 13.9, ease: Sine.easeOut, onComplete: this.introAnimComplete });
  }

  preloadCallback = () => {
    //this.refs.prevNextRef.showNextBtn(globalState.showPrevNextDelay);
  }


  componentWillUnmount() {
    //remove resize listener
    window.removeEventListener("resize", this.updateDimensions);
    this.killAnimations();
  }


  updateDimensions = () => {
    console.log('updateDimensions');
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, globalState.screensize.defaultWidth, globalState.screensize.defaultHeight);

    //this.treesRef.updateDimensions(globalState.screensize.defaultWidth, globalState.screensize.defaultHeight);
  }
  
  introAnimComplete = () => {
    console.log('animation complete');
  }
  
  showNext = () => {
    this.refs.prevNextRef.showNextBtn(0);
    globalState.updateMenuIsVisible();
  }

  handleNextClick = () => {
    console.log('Go clicked');
    this.refs.prevNextRef.updateNextHijacking(false);
    this.killAnimations();
    this.playOutro();
  }
  shrinkWaterColor = () => {
    frameAnimationReversed(this.watercolorRef, 644, 362, 6, 5, 29, 0.15, 0);
  }
  hideTrees = () => {
    this.treesRef.hideTrees();
  }
  outroAnimComplete = () => {
    //this.planeVideoRef.hideVideo();
    //this.planeLandingVideoRef.playVideo();
    //this.refs.prevNextRef.showNextBtn(2);
    this.planeVideoRef.pauseVideo();
    navigate("/intro/1");
  }

  //animate title screen elements out
  playOutro = () => {
    gsap.to(this.goBtnRef, 0.3, { autoAlpha: 0.0, delay: 0.0, ease: Sine.easeOut });
    gsap.to(this.richardRef, 0.3, { autoAlpha: 0.0, delay: 0.0, scaleX: 1, scaleY: 1, ease: Sine.easeOut });
    gsap.to(this.titleGogglesRef, 0.2, { autoAlpha: 0.0, delay: 0.3 });
    gsap.to(this.titleWelcomeRef, 0.2, { autoAlpha: 0.0, delay: 0.3, onComplete: this.hideTrees });
    gsap.to(this.watertowerRef, 0.3, { autoAlpha: 0.0, delay: 0.5, scaleX: 0, scaleY: 0, ease: Sine.easeOut });
    gsap.to(this.bandRef, 0.3, { autoAlpha: 0.0, delay: 0.7, scaleX: 0, scaleY: 0, ease: Sine.easeOut, onComplete: this.shrinkWaterColor });
    gsap.to(this.brothersRef, 0.3, { autoAlpha: 0.0, delay: 0.9, scaleX: 0, scaleY: 0, ease: Sine.easeOut, onComplete: this.outroAnimComplete });
  }


  //clear all animations
  killAnimations = () => {
    if (this.timeout1) {
      clearTimeout(this.timeout1);
      clearTimeout(this.timeout2);
      clearTimeout(this.timeout3);
      clearTimeout(this.timeout4);
      clearTimeout(this.timeout5);
      clearTimeout(this.timeout6);
    }
    gsap.killTweensOf(this.goBtnRef);
    gsap.killTweensOf(this.richardRef);
    gsap.killTweensOf(this.watertowerRef);
    gsap.killTweensOf(this.bandRef);
    gsap.killTweensOf(this.brothersRef);
    gsap.killTweensOf(this.titleWelcomeRef);
  }

  isTransitioning = () =>{
    this.planeVideoRef.pauseVideo();
  }




  render() {
    
    return (
      <>
        <link rel="prefetch" href="/intro/1" />
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.planeLandingVideo} />
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.planeLandingEndLoopVideo} />
        
        { /* Prev / Next buttons with hijack for outtro */}
        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation={"/intro/1"}
          nextHijack={true} nextCallback={this.handleNextClick}
          prevLocation={""}
          prevHijack={false}
          isTransitioning={this.isTransitioning}
        />

        
          <div className="fullpage-wrapper">
            <SEO title="Pine Point - Intro" />

            {data.planeVideo ? 
              <FullscreenVideo file={globalState.videoUrl + data.planeVideo} 
              poster={globalState.cdnUrl + data.planeVideoPoster} autoPlay={false} loop={false} ref={div => this.planeVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} />
              : ''
            }
            
            <div className="fullscreen-bg intro-bg" ref="fullscreenBgRef">

              <div className="watercolor" ref={div => this.watercolorRef = div} alt="Watercolor background" style={{ width: "644px", height: "362px", transform: `scale(${watercolorScale})`, backgroundImage: `url(${globalState.cdnUrl + data.watercolor})` }} />

              <div className="titleImagine" ref={div => this.titleImagineRef = div} alt="Imagine your hometown never changed..." style={{ left: "275px", top: "75px", width: "758px", height: "270px", transform: `scale(0.65)`, backgroundImage: `url(${globalState.cdnUrl + data.titleImagine})` }} />

              <div className="titleWould" ref={div => this.titleWouldRef = div} alt="Would it be so bad?" style={{ left: "630px", top: "190px", width: "602px", height: "115px", transform: `scale(0.6)`, backgroundImage: `url(${globalState.cdnUrl + data.titleWould})` }} />

              <div className="titleWelcome" ref={div => this.titleWelcomeRef = div} alt="Welcome to Pinepoint" style={{ left: "245px", top: "65px", width: "682px", height: "179px", transform: `scale(0.8)`, backgroundImage: `url(${globalState.cdnUrl + data.titleWelcome})` }} />

              <div className="titleGoggles" ref={div => this.titleGogglesRef = div} alt="By the Goggles: Paul Shoebridge + Michael Simons" style={{ left: "785px", top: "230px", width: "313px", height: "78px", transform: `scale(0.9)`, backgroundImage: `url(${globalState.cdnUrl + data.titleGoggles})` }} />

              <img ref={div => this.watertowerRef = div} className="watertower" src={globalState.cdnUrl + data.watertower} alt="Water Tower" style={{ left: "260px", top: "263px" }} />

              <Trees ref={div => this.treesRef = div} treeA={globalState.cdnUrl + data.treeA} treeB={globalState.cdnUrl + data.treeB} />

              <img ref={div => this.bandRef = div} className="band" src={globalState.cdnUrl + data.band} alt="Band" style={{ left: "585px", top: "453px" }} />

              <img ref={div => this.brothersRef = div} className="brothers" src={globalState.cdnUrl + data.brothers} alt="Brothers" style={{ left: "253px", top: "496px" }} />

            </div>

            <button onClick={this.handleNextClick} ref={div => this.goBtnRef = div} className="goBtn" alt="Go" style={{ width: "87px", height: "45px", right: "320px", bottom: "200px", backgroundImage: `url(${globalState.cdnUrl + data.goBtn})` }}></button>

            <img ref={div => this.richardRef = div} className="richard" src={globalState.cdnUrl + data.richard} alt="Richard" style={{ right: "70px", bottom: "-10px" }} />

          </div> 
          
          {!globalState.isActivated ?
            <Preloader ref="preloaderRef" globalState={globalState} data={data} callback={this.initAnimation} /> : ''
          }
        
      </>
    );
  }
}


export default IndexPage
