import React from "react"
import { gsap, Quad } from "gsap"


//const Tree = ({ num, file }) => {
class Tree extends React.Component {

    constructor(props) {
        super(props);
        //this.treeRef = null;
        this.state = { grow: '' }

        console.log("tree");
        
    }
    
    showTree = () => {
        gsap.to(this.refs.treeRef, 0.3, {scale:1, opacity: 1, ease: Quad.easeOut})
    }

    hideTree = () => {
        gsap.to(this.refs.treeRef, 0.3, {scale:0.2, opacity: 0, ease: Quad.easeOut})
    }

    render(){
        return (
            <div className={"tree tree--" + this.props.num + " " + this.props.type}>
                <img ref="treeRef" src={ this.props.file } alt="tree" />
            </div>
        );
    }
}

export default Tree
